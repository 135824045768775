<template>
    <div class="BrandingView ViewWrapper">
        <clv-head-meta :title="$tc('entity Branding', 1)"></clv-head-meta>
        <view-header>
            <h1>{{ $tc('entity Branding', 1) }}</h1>
        </view-header>
        <view-container>
            <div class="row mt-4">
                <div class="col-12 col-md-8">
                    <div class="gk-card p-0">
                        <div class="border-bottom" v-if="i18nAddOnEnabled">
                            <select class="form-control border-0 rounded-0" v-model="currentLanguageKey">
                                <option :label="$t('EN')" :value="'EN'">{{ $t('EN') }}</option>
                                <option :label="$t('EL')" :value="'EL'">{{ $t('EL') }}</option>
                            </select>
                        </div>
                        <div class="py-3 px-4">

                            <!-- LOADER -->
                            <div class="ElementLoading-Placeholder" v-if="stateIsChanging">
                                <element-loading :active="true" background-color="transparent"></element-loading>
                            </div>

                            <!-- EMPTY STATE 2020-07-19 -->
                            <simple-state-not-found v-if="notFound">
                                <p class="mb-0 size-6" slot="title">{{ $t('common_state_not_found') }}</p>
                                <p class="mb-0 text-muted small" slot="description">{{ $t('Please contact support') }}.</p>
                            </simple-state-not-found>

                            <el-form :model="formFields"
                                     :rules="formFieldsRules"
                                     ref="newInstanceForm"
                                     :label-position="'top'"
                                     class="cltlr-form-label-style-1"
                                     @submit.native.prevent
                                     v-if="displayForm">

                                <el-form-item :label="$t('Name')" prop="name">
                                    <el-input v-model="formFields.name"></el-input>
                                </el-form-item>

                                <el-form-item :label="$t('Type')" prop="type">
                                    <el-select v-model="formFields.type" class="w-100">
                                        <el-option :label="$t('ORGANIZATION')" :value="'ORGANIZATION'">{{ $t('ORGANIZATION') }}</el-option>
                                        <el-option :label="$t('INDIVIDUAL')" :value="'INDIVIDUAL'">{{ $t('INDIVIDUAL') }}</el-option>
                                        <el-option :label="$t('OTHER')" :value="'OTHER'">{{ $t('OTHER') }}</el-option>
                                    </el-select>
                                </el-form-item>

                                <el-form-item :label="$t('Website URL')" prop="websiteUrl">
                                    <el-input v-model="formFields.websiteUrl"></el-input>
                                </el-form-item>

                                <el-form-item :label="$t('Domain Name')" prop="domainName">
                                    <el-input v-model="formFields.domainName"></el-input>
                                </el-form-item>

                                <el-form-item :label="$t('Address')" prop="address">
                                    <el-input v-model="formFields.address"></el-input>
                                </el-form-item>

                                <el-form-item :label="$t('City')" prop="city">
                                    <el-input v-model="formFields.city"></el-input>
                                </el-form-item>

                                <el-form-item :label="$t('ZIP Code')" prop="zipCode">
                                    <el-input v-model="formFields.zipCode"></el-input>
                                </el-form-item>

                                <el-form-item :label="$t('Region')" prop="region">
                                    <el-input v-model="formFields.region"></el-input>
                                </el-form-item>

                                <el-form-item :label="$t('Country')" prop="country">
                                    <el-input v-model="formFields.country"></el-input>
                                </el-form-item>

                                <el-form-item :label="$t('Logo')" prop="logo">
                                    <el-input v-model="formFields.logo"></el-input>
                                </el-form-item>

                                <el-form-item :label="$t('Logo (Square)')" prop="logoSquare">
                                    <el-input v-model="formFields.logoSquare"></el-input>
                                </el-form-item>

                                <el-form-item :label="$t('Logo (Icon)')" prop="logoIcon">
                                    <el-input v-model="formFields.logoIcon"></el-input>
                                </el-form-item>

                                <el-form-item :label="$t('Logo (Favicon)')" prop="logoFavicon">
                                    <el-input v-model="formFields.logoFavicon"></el-input>
                                </el-form-item>

                                <el-form-item :label="$t('Color Primary')" prop="colorPrimary">
                                    <el-color-picker v-model="formFields.colorPrimary"></el-color-picker>
                                    <!--<el-input v-model="formFields.colorPrimary"></el-input>-->
                                </el-form-item>

                                <el-form-item :label="$t('Color Secondary')" prop="colorSecondary">
                                    <el-color-picker v-model="formFields.colorSecondary"></el-color-picker>
                                    <!--<el-input v-model="formFields.colorSecondary"></el-input>-->
                                </el-form-item>

                                <el-form-item :label="$t('First Color Accent')" prop="colorAccent1">
                                    <el-color-picker v-model="formFields.colorAccent1"></el-color-picker>
                                    <!--<el-input v-model="formFields.colorAccent1"></el-input>-->
                                </el-form-item>

                                <el-form-item :label="$t('Second Color Accent')" prop="colorAccent2">
                                    <el-color-picker v-model="formFields.colorAccent2"></el-color-picker>
                                    <!--<el-input v-model="formFields.colorAccent2"></el-input>-->
                                </el-form-item>

                                <el-form-item class="mt-2 mb-0">
                                    <button type="button" class="btn btn-primary" @click="submitForm">{{ $t('common_action_save') }}</button>
                                    <!--<button type="button" class="btn btn-secondary btn-sm" @click="resetForm">Reset</button>-->
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
            </div>
        </view-container>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { BrandingService } from '@/common/services/api.service';
import ElValidationUtils from '@/common/utils/el-validation.utils';

/**
 * View to manage Branding domain.
 * It i18n add-on is enabled it will display a select box with the supported languages.
 *
 * @author Dimitris Gkoulis
 */
export default {
    name: 'BrandingView',
    data () {
        return {
            tenantAccountId: null,
            tenantAccountDefaultLanguageKey: 'EN',
            currentLanguageKey: 'EN',
            i18nAddOnEnabled: window.cs3w.get('i18nAddOnEnabled', false),

            // Persistence //////////
            fetching: false,
            saving: false,
            notFound: false,
            formFieldsDefault: {
                id: null,
                tenantAccountId: '',
                languageKey: 'EN',
                name: '',
                type: 'OTHER',
                websiteUrl: '',
                domainName: '',
                address: '',
                city: '',
                zipCode: '',
                region: '',
                country: '',
                logo: '',
                logoSquare: '',
                logoIcon: '',
                logoFavicon: '',
                colorPrimary: '',
                colorSecondary: '',
                colorAccent1: '',
                colorAccent2: ''
            },
            formFields: {
                id: null,
                tenantAccountId: '',
                languageKey: 'EN',
                name: '',
                type: 'OTHER',
                websiteUrl: '',
                domainName: '',
                address: '',
                city: '',
                zipCode: '',
                region: '',
                country: '',
                logo: '',
                logoSquare: '',
                logoIcon: '',
                logoFavicon: '',
                colorPrimary: '',
                colorSecondary: '',
                colorAccent1: '',
                colorAccent2: ''
            },
            formFieldsRules: {
                // @help : keep the front-end and back-end validation the same [IMPORTANT]!!!!
                name: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(2, 255)
                ],
                type: [
                    ElValidationUtils.requiredValidator()
                ],
                websiteUrl: [
                    ElValidationUtils.optionalValidator()
                ],
                domainName: [
                    ElValidationUtils.optionalValidator()
                ],
                address: [
                    ElValidationUtils.optionalValidator()
                ],
                city: [
                    ElValidationUtils.optionalValidator()
                ],
                zipCode: [
                    ElValidationUtils.optionalValidator()
                ],
                region: [
                    ElValidationUtils.optionalValidator()
                ],
                country: [
                    ElValidationUtils.optionalValidator()
                ],
                logo: [
                    ElValidationUtils.optionalValidator()
                ],
                logoSquare: [
                    ElValidationUtils.optionalValidator()
                ],
                logoIcon: [
                    ElValidationUtils.optionalValidator()
                ],
                logoFavicon: [
                    ElValidationUtils.optionalValidator()
                ],
                colorPrimary: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(null, 12)
                ],
                colorSecondary: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(null, 12)
                ],
                colorAccent1: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(null, 12)
                ],
                colorAccent2: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(null, 12)
                ]
            }
        };
    },
    computed: {
        stateIsChanging () {
            return this.fetching || this.saving;
        },
        displayForm () {
            return this.notFound === false && this.fetching === false && this.saving === false;
        }
    },
    watch: {
        'currentLanguageKey' () {
            // Reset the form before fetch.
            this.resetForm();
            // Fetch the instance on each language change.
            this.fetchInstance();
        }
    },
    beforeMount () {
        // Get data from store.
        this.tenantAccountId = this.$store.getters['application/tenantAccountId'];
        this.tenantAccountDefaultLanguageKey = this.$store.getters['application/tenantAccountDefaultLanguageKey'];
        this.currentLanguageKey = this.tenantAccountDefaultLanguageKey;

        // Fetch instance for the first time.
        // We expect the instance with language key the default account's language key.
        this.fetchInstance();
    },
    methods: {
        fetchInstance () {
            // Reset notFound.
            this.notFound = false;

            // Start fetching.
            this.fetching = true;

            // Fetch instance.
            BrandingService.getBranding(this.tenantAccountId, this.currentLanguageKey)
                .then(({ data }) => {
                    this.formFields = cloneDeep(data);
                    // @future : Make sure that no field is null.
                })
                .catch((ignoredError) => {
                    // Resource not found.
                    this.notFound = true;
                })
                .finally(() => {
                    // Stop fetching.
                    this.fetching = false;
                });
        },
        saveInstance () {
            // You should call 'submitForm' first. It validates the form.

            // Start loader.
            this.saving = true;

            // Perform the save request.
            BrandingService.saveBranding(this.formFields)
                .then((ignoredData) => {
                    // if you need data: { data }
                    this.toastDefault('Settings updated');
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                })
                .finally(() => {
                    // Stop loader.
                    this.saving = false;
                });
        },

        // Form Controls //////////
        submitForm () {
            // Start loader.
            this.saving = true;

            // Validate form.
            this.$refs['newInstanceForm'].validate((valid) => {
                if (valid) {
                    this.saveInstance();
                } else {
                    this.saving = false;
                    return false;
                }
            });
        },
        resetForm () {
            // @help : try-catch is necessary because form sometimes is hidden (due to 'not-found'). Normal behavior.
            try {
                this.$refs['newInstanceForm'].resetFields();
            } catch (ignoredError) {}
        }
    }
};
</script>

<style lang="scss" scoped>
    .BrandingView {

        .el-textarea__inner {
            min-height: 120px !important;
        }

        .el-form-item {

            .el-textarea {

                textarea,
                .el-textarea__inner {
                    min-height: 120px !important;
                }
            }
        }
    }
</style>
